import React, { createRef } from "react";
import gsap from 'gsap'
import PropTypes from "prop-types";

const Button = props => {
  const { children, onMouseDown, ...restProps } = props
  const buttonRef = createRef()
  const animateWave = event => {
    const wave = gsap.timeline()
    wave
      .to(buttonRef.current, 0, { boxShadow: 'rgba(132, 132, 132, 0.15) 0px 0px 0px 0px'})
      .to(buttonRef.current, 0.2, { boxShadow: 'rgba(132, 132, 132, 0.30) 0px 0px 12px 3px'})
      .to(buttonRef.current, 0.2, { boxShadow: 'rgba(132, 132, 132, 0) 0px 0px 24px 8px'})
    // TweenLite.fromTo(buttonRef.current, 0.4, { boxShadow: 'rgba(132, 132, 132, 0.31) 0px 0px 0px 0px'}, { boxShadow: 'rgba(132, 132, 132, 0.31) 0px 0px 20px 12px'})
    onMouseDown && onMouseDown(event)
  }

  return (
    <a {...restProps} ref={buttonRef} onMouseDown={animateWave}>
      {children}
    </a>
  );
};

Button.propTypes = {
};

export default Button;
