import React from "react";
import PropTypes from "prop-types";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import './Crossfade.css';

class Crossfade extends React.Component {
  state = {
    prevNode: null,
    nextNode: null,
  };

  componentWillMount(nextProps) {
    this.updateNodes(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.updateNodes(nextProps);
  }

  updateNodes(nextProps) {
    if (!this.state.prevNode) {
      this.setState({
        prevNode: nextProps.children
      });
    } else if(this.state.prevNode.key !== nextProps.children.key) {
      this.setState({
        prevNode: null,
        nextNode: nextProps.children
      }, () => {
        setTimeout(() => {
          this.setState({
            prevNode: this.state.nextNode,
            nextNode: null
          });
        }, 150)
      });
    } else if (this.state.prevNode.key === nextProps.children.key) {
      this.setState({
        prevNode: nextProps.children
      });
    }
  }

  render() {
    return (
      <div className="wrapper">
        <TransitionGroup>
          <CSSTransition
            key={this.state.prevNode && this.state.prevNode.key}
            classNames="fade"
            timeout={150}
          >
            <div className="fading-wrapper">
              {this.state.prevNode}
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>
    );
  }
}

Crossfade.propTypes = {
  duration: PropTypes.number,
};

export default Crossfade;