import React from "react";
import PropTypes from "prop-types";

MainContentDetails.propTypes = {

};

function MainContentDetails(props) {
  return (
    <>
      <h1 className="jumbo-title">Matthias Meier</h1>
      <h3 className="jumbo-subtitle">Senior Frontend Developer</h3>
      <p>Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum </p>
      <p className="mb-2">
        <b>Availability</b> Freelance<br/>
        <b>Location</b> Zurich<br/>
        <b>Nationality</b> Swiss<br/>
      </p>
    </>
  );
}

export default MainContentDetails;