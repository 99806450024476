import React, { createRef } from "react";
import photoPortrait from '../../static/img/portrait-photo.png';
import photoHello from '../../static/img/hello.png';
import photoBlacky from '../../static/img/style-elements/blacky.svg';
import skillLogoTs from '../../static/img/skill-cloud-logos/ts.png';
import skillLogoAngular from '../../static/img/skill-cloud-logos/angular.png';
import skillLogoSass from '../../static/img/skill-cloud-logos/sass.png';
import skillLogoGit from '../../static/img/skill-cloud-logos/git.png';
import skillLogoWebpack from '../../static/img/skill-cloud-logos/webpack.png';
import skillLogoReact from '../../static/img/skill-cloud-logos/react.png';
import skillLogoRx from '../../static/img/skill-cloud-logos/rxjs.png';
import skillLogoPhotoshop from '../../static/img/skill-cloud-logos/photoshop.svg';
import skillLogoHtml5 from '../../static/img/skill-cloud-logos/html5.png';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import Layout from '../components/Layout';
import gsap from "gsap";
import MainContentDefault from "../components/main-page/default/MainContentDefault";
import MainContentDetails from "../components/main-page/details/MainContentDetails";
import Crossfade from "../components/animation/Crossfade";
import Button from "../components/Button";
import LinkFade from "../components/animation/LinkFade";

const mainView = {
  body: {
    height: '100vh',
    minHeight: '760px',
    overflow: 'hidden',
  },
  mainSection: {
    minHeight: 'calc(100vh - 16rem)'
  },
  whiteContentMain: {
    display: 'block',
  },
  whiteContentDetail: {
    display: 'none',
  },
  profileImage: {
    width: '650px',
    height: '550px',
    top: '0',
    left: '10%',
  },
};

const detailsView = {
  body: {
    height: 'auto',
    minHeight: '760px',
    overflow: 'auto',
  },
  mainSection: {
    minHeight: 'calc(0vh - 0rem)'
  },
  whiteContentMain: {
    display: 'none',
  },
  whiteContentDetail: {
    display: 'block',
  },
  profileImage: {
    width: '430px',
    height: '430px',
    top: '50px',
    left: '20%',
  },
};

export default class IndexPage extends React.Component {
  state = {
    isDetailsView: false,
  };

  profileImage = createRef();
  mainSection = createRef();
  darkSection = createRef();
  //whiteContentMain = createRef();
  //whiteContentDetail = createRef();

  componentDidMount() {
    gsap.set(document.body, mainView.body);
    gsap.set(this.profileImage.current, mainView.profileImage);
    gsap.set(this.mainSection.current, mainView.mainSection);
    //gsap.set(this.whiteContentDetail.current, mainView.whiteContentDetail);
  }

  componentWillUnmount() {
    gsap.set(document.body, {
      height: '',
      minHeight: '',
    });
  }

  toggleDetails = () => {
    if (!this.state.isDetailsView) {
      // SHOW DETAILS
      this.setState({ isDetailsView: true })
      gsap.set(document.body, detailsView.body);
      //gsap.set(this.whiteContentMain.current, detailsView.whiteContentMain);
      //gsap.set(this.whiteContentDetail.current, detailsView.whiteContentDetail);
      gsap.fromTo(this.profileImage.current, 0.3, mainView.profileImage, detailsView.profileImage);
      gsap.fromTo(this.mainSection.current, 0.3, mainView.mainSection, detailsView.mainSection);
    } else {
      // SHOW MAIN
      this.setState({ isDetailsView: false })
      if (window.scrollY !== 0) {
        gsap.to(window, {duration: 0.3, scrollTo: 0});
      }
      //gsap.set(this.whiteContentMain.current, mainView.whiteContentMain);
      //gsap.set(this.whiteContentDetail.current, mainView.whiteContentDetail);
      gsap.fromTo(this.profileImage.current, 0.3, detailsView.profileImage, mainView.profileImage);
      gsap.fromTo(this.mainSection.current, 0.3, detailsView.mainSection, mainView.mainSection);

      gsap.delayedCall(0.3, () => {
        gsap.set(document.body, mainView.body);
      });
    }
  };

  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark
    const pullUpToggleIcon = () => this.state.isDetailsView ? 'ion-ios-arrow-down' : 'ion-ios-arrow-up'

    return (
      <Layout>
        <section className="section main-section" ref={this.mainSection}>
          <div className="container">
            <div className="content">
              <div className="columns jumbo-wrapper">
                <div className="column" ref={this.whiteContentMain}>
                  <Crossfade>
                    { this.state.isDetailsView ? <MainContentDetails key={0}/> : <MainContentDefault key={1}/> }
                  </Crossfade>
                </div>
                <div className="column">
                  <figure className="image profile-image" ref={this.profileImage}>
                    <img src={photoPortrait}/>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section dark-section" ref={this.darkSection}>
          <figure className="image dark-section-divider">
            <img src={photoBlacky}/>
            <div className="background-spacer"></div>
            <Button className="button btn-primary pull-up-down-button" onClick={this.toggleDetails}>
              <i className={'icon ' + pullUpToggleIcon()}></i>
            </Button>
          </figure>
          <div className="pullup-footer-wrapper">
            <div>
              <div className="container">
                <div className="content">
                  <div className="columns">
                    <div className="column">
                      <Crossfade>
                        { !this.state.isDetailsView ?
                        <div className="sm-links" key={1}>
                          <a href="https://medium.com/@matthiasmeierIO" target="_blank" className="sm-link sm-medium"></a>
                          <a href="https://www.linkedin.com/in/matthias-meier-74120286/" target="_blank" className="sm-link sm-linkedin"></a>
                        </div>
                          : <div key={2}></div>}
                      </Crossfade>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dark-section-container">
            <div className="container">
              <div className="content">
                <div className="columns">
                  <div className="column">
                    <h2 className="">What I'm great at</h2>
                    <div className="skill-wrapper">
                      <span className="skill-title">Frontend Development</span>
                      <ul className="skill-description">
                        <li>SPA's</li>
                        <li>Architecture</li>
                        <li>Styling</li>
                        <li>Teaching</li>
                      </ul>
                      <div className="skill-bar">
                        <div style={{width: "90%"}}> </div>
                      </div>
                    </div>
                    <div className="skill-wrapper">
                      <span className="skill-title">UI- & UX-Design</span>
                      <ul className="skill-description">
                        <li>Mockups</li>
                        <li>Design</li>
                        <li>Web & Mobile</li>
                      </ul>
                      <div className="skill-bar">
                        <div style={{width: "60%"}}> </div>
                      </div>
                    </div>
                    <div className="skill-wrapper">
                      <span className="skill-title">Photography & Videography</span>
                      <ul className="skill-description">
                        <li>Abstract</li>
                        <li>Nature</li>
                        <li>Travel</li>
                        <li>Portrait</li>
                      </ul>
                    </div>
                    <div className="skill-wrapper">
                      <span className="skill-title">3D Art</span>
                      <ul className="skill-description">
                        <li>Modeling</li>
                        <li>Animation</li>
                        <li>Game Development</li>
                      </ul>
                    </div>
                    <h2 className="">Where I got some experience</h2>
                    <div className="skill-wrapper">
                      <span className="skill-title">Machine & Deep Learning</span>
                      <ul className="skill-description">
                        <li>Modeling</li>
                        <li>Webservices</li>
                      </ul>
                      <div className="skill-bar">
                        <div style={{width: "30%"}}> </div>
                      </div>
                    </div>
                    <div className="skill-wrapper mb-2">
                      <span className="skill-title">Backend Development</span>
                      <ul className="skill-description">
                        <li>Databases</li>
                        <li>API's</li>
                      </ul>
                      <div className="skill-bar">
                        <div style={{width: "20%"}}> </div>
                      </div>
                    </div>
                    <div className="buttons">
                      <LinkFade duration={0.2} className="button btn-primary is-inverted" to="/contact">
                        Create Toghether
                      </LinkFade>
                      <LinkFade duration={0.2} className="button btn-secondary is-inverted" to="/portfolio">
                        Portfolio
                      </LinkFade>
                    </div>
                  </div>
                  <div className="column">
                    <div className="skill-cloud">
                      <div>
                        <div>
                          <div className="skill-logo-wrapper">
                            <a href="" target="_blank" className="skill-logo logo-1">
                              <figure className="image">
                                <img src={skillLogoTs}/>
                              </figure>
                            </a>
                          </div>
                          <div className="skill-logo-wrapper">
                            <a href="" target="_blank" className="skill-logo logo-2">
                              <figure className="image">
                                <img src={skillLogoAngular}/>
                              </figure>
                            </a>
                          </div>
                          <div className="skill-logo-wrapper">
                            <a href="" target="_blank" className="skill-logo logo-3">
                              <figure className="image">
                                <img src={skillLogoSass}/>
                              </figure>
                            </a>
                          </div>
                        </div>
                        <div>
                          <div className="skill-logo-wrapper">
                            <a href="" target="_blank" className="skill-logo logo-4">
                              <figure className="image">
                                <img src={skillLogoGit}/>
                              </figure>
                            </a>
                          </div>
                          <div className="skill-logo-wrapper">
                            <a href="" target="_blank" className="skill-logo logo-5">
                              <figure className="image">
                                <img src={skillLogoWebpack}/>
                              </figure>
                            </a>
                          </div>
                          <div className="skill-logo-wrapper">
                            <a href="" target="_blank" className="skill-logo logo-6">
                              <figure className="image">
                                <img src={skillLogoReact}/>
                              </figure>
                            </a>
                          </div>
                        </div>
                        <div>
                          <div className="skill-logo-wrapper">
                            <a href="" target="_blank" className="skill-logo logo-7">
                              <figure className="image">
                                <img src={skillLogoRx}/>
                              </figure>
                            </a>
                          </div>
                          <div className="skill-logo-wrapper">
                            <a href="" target="_blank" className="skill-logo logo-8">
                              <figure className="image">
                                <img src={skillLogoPhotoshop}/>
                              </figure>
                            </a>
                          </div>
                          <div className="skill-logo-wrapper">
                            <a href="" target="_blank" className="skill-logo logo-9">
                              <figure className="image">
                                <img src={skillLogoHtml5}/>
                              </figure>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] },
      filter: { frontmatter: { templateKey: { eq: "blog-post" } }}
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`
