import React from "react";
import PropTypes from "prop-types";
import photoHello from "../../../../static/img/hello.png";
import Button from "../../Button";
import LinkFade from "../../animation/LinkFade";

MainContentDefault.propTypes = {

};

function MainContentDefault(props) {
  return (
    <>
      <figure className="image hello">
        <img src={photoHello} alt="Hello"/>
      </figure>
      <h1 className="jumbo-title">I'm Matt</h1>
      <p className="mb-2" style={{maxWidth: '100%'}}>Known to be passionate about beautiful UI's, perfectly orchestrated Observable Streams, well crafted Web Componends and the future of artificial intelligence. I'm excited to hear about your latest project idea!</p>
      <div className="buttons">
        <LinkFade duration={0.2} className="button btn-primary" to="/contact">
          Create Toghether
        </LinkFade>
        <LinkFade duration={0.2} className="button btn-secondary" to="/portfolio">
          Portfolio
        </LinkFade>
      </div>
    </>
  );
}

export default MainContentDefault;